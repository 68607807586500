<template>
  <div class="flex flex-col items-center rounded-md shadow-sm bg-white text-big-stone border-t-8 border-big-stone">
    <!-- Weather & Time -->
    <div class="flex flex-col xl:flex-row items-center w-full border-t divide-y xl:divide-y-0 xl:divide-x border-gray-200 xl:divide-gray-200">
      <div class="w-full xl:w-1/2">
        <div v-if="temperature" title="Average Temperature" class="flex flex-col items-center justify-center space-y-1 p-4 h-24">
          <div class="font-bold text-3xl lg:text-4xl xxl:text-5xl leading-none lg:leading-none">
            {{ Math.round(temperature) }}&nbsp;°F
          </div>
          <div class="text-sm font-semibold">Average Temperature</div>
        </div>
        <div v-else class="flex py-4 px-8 font-bold text-xl lg:text-2xl xxl:text-3xl leading-none justify-center">n/a</div>
      </div>
      <div class="w-full xl:w-1/2">
        <div class="flex flex-col items-center justify-center p-4 w-full">
          <div class="font-bold text-2xl leading-none">
              {{ range.from }}<span class="font-normal opacity-50 px-3">&mdash;</span>{{ range.to }}
          </div>
        </div>
      </div>
    </div>
    <!-- Efficiency -->
    <div class="flex flex-row w-full border-t divide-x items-center">
      <div class="w-1/3 flex flex-col items-center justify-center space-y-1 h-full p-4">
        <div class="font-bold text-3xl lg:text-4xl leading-none lg:leading-none">
          {{ harvestVendsPercentage }}%
        </div>
        <div class="text-sm font-semibold">Harvets/Vends</div>
      </div>
      <div class="w-2/3 px-4 lg:text-lg text-gray-500 flex flex-row justify-start py-6 space-x-2">
        <div>Harvests: <span class="font-semibold text-gray-700">{{ stats.harvests }}</span></div>
        <div>Vends: <span class="font-semibold text-gray-700">{{ stats.ice_vends }}</span></div>
      </div>
    </div>
    <!-- Revenue -->
    <div class="flex flex-row w-full border-t divide-x items-center">
      <div class="w-1/3 flex flex-col items-center justify-center space-y-1 h-full p-4">
        <div class="font-bold text-3xl lg:text-4xl leading-none lg:leading-none">
          ${{ (stats.ice_revenue + stats.water_revenue) / 100 }}
        </div>
        <div class="text-sm font-semibold">Total Revenue</div>
      </div>
      <div class="w-2/3 px-4 lg:text-lg text-gray-500 flex flex-row justify-start py-6 space-x-2">
        <div>I. Revenue: <span class="font-semibold text-gray-700">${{ stats.ice_revenue / 100 }}</span></div>
        <div>W. Revenue: <span class="font-semibold text-gray-700">${{ stats.water_revenue / 100 }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'

export default {
  name: 'ReportsConditions',

  props: ['temperature', 'range', 'stats'],

  setup(props) {
    const harvestVendsPercentage = computed(() => {
      if (! props.stats.ice_vends) {
        return 0
      }

      return Math.round(props.stats.harvests / props.stats.ice_vends * 100)
    })

    return {
      harvestVendsPercentage
    }
  },
}
</script>
