<template>
  <layout class="relative">
    <template v-if="state.isLoading">
      <div class="absolute inset-0 flex items-center justify-center mt-12 md:mt-0">
        <icon-loading class="w-6 h-6 text-blumine mr-2" /> Loading...
      </div>
    </template>
    <template v-else-if="state.error">
      <alert-warning title="Error">
        {{ state.error }}
      </alert-warning>
    </template>
    <template v-else-if="! state.hasAccess">
      <alert-warning title="Access denied">
        You are not allowed to view this machine
      </alert-warning>
    </template>
    <template v-else>
      <filter-header
        v-model:isRangeModalOpen="state.isRangeModalOpen"
        :active-machine="state.activeMachine"
        :machines="state.machines"
        :realtime="false"
      />

      <div class="flex flex-col space-y-4 md:space-y-6 lg:space-y-8">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 xl:gap-6 max-w-7xl">

          <!-- MACHINE INFO -->
          <div class="flex flex-col gap-y-2">
            <div>
              <h3 class="mb-1 font-semibold uppercase text-sm">Conditions</h3>
              <machine-conditions
                :temperature="state.data.average_temperature"
                :stats="state.data.vend_stats"
                :range="{
                  from: route.query.from,
                  to: route.query.to,
                }"
              />
            </div>
          </div>

          <!-- MACHINE Vends -->
          <div class="flex flex-col justify-between">
            <div>
              <h3 class="mb-1 font-semibold uppercase text-sm">Ice Vends</h3>
              <machine-ice-vends :data="state.data.vend_stats" />
            </div>
            <div class="mt-2">
              <h3 class="mb-1 font-semibold uppercase text-sm">Water Vends</h3>
              <machine-water-vends :data="state.data.vend_stats" />
            </div>
          </div>
        </div>

        <chart-card :is-loading="state.isLoading" title="Activity Per Hour">
          <activity-per-hour-chart :data="state.data.data" :modifier="1" />
        </chart-card>

        <chart-card :is-loading="state.isLoading" title="Hourly Report">
          <hourly-report-table
            :data="state.data.data"
            time-format="HH:mm (z)"
            :timezone="state.activeMachine.timezone"
          />
        </chart-card>
      </div>
    </template>

    <date-range-modal
      v-if="state.isRangeModalOpen"
      v-model:isRangeModalOpen="state.isRangeModalOpen"
      :range="state.range"
      @on-submit="onDateRangeModalSubmit"
    />
  </layout>
</template>

<script>
import { reactive } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import http from '@/services/http.js'
import { store } from '@/services/store.js'
import Layout from '@/layouts/Default.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import ActivityPerHourChart from '@/components/reports/ActivityPerHourChart.vue'
import FilterHeader from '@/components/reports/FilterHeader.vue'
import HourlyReportTable from '@/components/reports/HourlyReportTable.vue'
import MachineConditions from '@/components/reports/MachineConditions.vue'
import MachineIceVends from '@/components/reports/MachineIceVends.vue'
import MachineWaterVends from '@/components/reports/MachineWaterVends.vue'
import ChartCard from '@/components/reports/ChartCard.vue'
import { IconLoading } from '@/components/icons'
import DateRangeModal from '@/components/modals/DateRange.vue'

export default {
  name: 'ReportsRealTimeHourly',

  components: {
    Layout,
    ChartCard,
    ActivityPerHourChart,
    FilterHeader,
    HourlyReportTable,
    MachineConditions,
    MachineIceVends,
    MachineWaterVends,
    AlertWarning,
    IconLoading,
    DateRangeModal,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      machines: [],
      activeMachine: {},
      isLoading: true,
      hasAccess: true,
      isRangeModalOpen: false,
      range: {
        from: route.query.from,
        to: route.query.to,
      },
      error: null
    })

    const init = async () => {
      try {
        const { data: machines } = await http.get('user/machines')
        const { data: machine } = await http.get(`/machine/${parseInt(route.params.machineId)}`)

        if (! machine) {
          state.hasAccess = false

          return
        }

        state.machines = machines.allowed_locations.filter(m => !m.is_technician)
        state.activeMachine = machine.model
        store.selectedMachineId = machine.model.location_id

        await getData()
      } catch (e) {
        if (e.response) {
          state.error = e.response.data.Message
        } else {
          state.error = 'Something went wrong. Please, try again.'
        }
      }

      state.isLoading = false
    }

    const getData = async () => {
      state.error = null

      try {
        const [{ data }] = await Promise.all([
          http.get(`report/${state.activeMachine.location_id}/hourly-range?start=${route.query.from}&end=${route.query.to}`)
        ])
        state.data = data
      } catch (e) {
        if (e.response) {
          state.error = e.response.data.Message
        } else {
          state.error = 'Something went wrong. Please, try again.'
        }
      }

      state.isLoading = false
    }

    const onDateRangeModalSubmit = (range) => {
      state.isRangeModalOpen = false
      state.range = range
      goToHourlyReport()
    }

    const goToHourlyReport = () => {
      router.push({
        name: 'ReportsRealTimeHourly',
        params: { machineId: state.activeMachine.location_id },
        query: { from: state.range.from, to: state.range.to }
      })
    }

    init()

    onBeforeRouteUpdate(() => {
      setTimeout(() => {
        state.isLoading = true
        init()
      }, 100)
    })

    return {
      state,
      route,
      onDateRangeModalSubmit,
    }
  }
}
</script>
